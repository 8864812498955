<template>
  <!-- 不同文件类型展示不同图标 -->
  <div class="preview_header">
    <div class="preview-header-l">
      <svg class="icon" aria-hidden="true">
        <use
          :href="
            allIcon[showData.fileType] || allIcon[showData.type] || defaultIcon
          "
        ></use>
      </svg>
      <div class="preview-header-l-name">{{ showData.name }}</div>
      <span>{{ showData.lastmodifydate || "" }}</span>
    </div>
    <!-- 操作按钮 -->
    <div class="preview-header-m">
      <div v-if="previewType === 'useNative'">
        <i
          class="el-icon-zoom-out zoom_btn"
          @click="handleActions('zoomOut')"
        ></i>
        <i
          class="el-icon-zoom-in zoom_btn"
          @click="handleActions('zoomIn')"
        ></i>
      </div>

      <div
        class="preview-header-option"
        v-for="(item, index) in showBtn"
        :key="index"
        @click="fileDeal(item)"
      >
        <svg
          class="icon"
          aria-hidden="true"
          v-if="item === 'replace'"
          style="transform: rotate(180deg)"
        >
          <use :href="allIcon['download']"></use>
        </svg>
        <svg class="icon" aria-hidden="true" v-if="item !== 'replace'">
          <use :href="allIcon[item]"></use>
        </svg>

        {{ btnConfig[item] }}
      </div>
      <el-popover
        placement="bottom-start"
        popper-class="pop_box"
        trigger="hover"
        v-if="hideBtn.length > 0"
      >
        <svg
          class="icon"
          aria-hidden="true"
          style="width: 17px; height: 17px"
          slot="reference"
        >
          <use :href="allIcon['dropdown']"></use>
        </svg>

        <ul class="more-button-ul">
          <li
            v-for="(item, index) in hideBtn"
            :key="index"
            @click="fileDeal(item)"
            class="more-button-li"
            style=""
          >
            <svg
              class="icon"
              aria-hidden="true"
              v-if="item === 'replace'"
              style="
                transform: rotate(180deg);
                width: 17px;
                height: 17px;
                margin-right: 10px;
              "
            >
              <use :href="allIcon['download']"></use>
            </svg>
            <svg
              class="icon"
              aria-hidden="true"
              v-if="item !== 'replace'"
              style="width: 17px; height: 17px; margin-right: 10px"
            >
              <use :href="allIcon[item]"></use>
            </svg>

            {{ btnConfig[item] }}
          </li>
        </ul>
      </el-popover>
    </div>
    <!-- 关闭icon -->
    <div class="preview-header-r" @click="closePreview">
      <svg class="icon" aria-hidden="true">
        <use href="#icon-close"></use>
      </svg>
    </div>
  </div>
</template>

<script>
import FILEICON from "@/views/ccFileCenter/config/fileIconLibrary";
export default {
  props: {
    showData: {
      type: Object,
      default: () => {},
    },
    showBtn: {
      type: Array,
      default: () => [],
    },
    hideBtn: {
      type: Array,
      default: () => [],
    },
      previewType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      allIcon: FILEICON, // 文件图标
      defaultIcon: FILEICON.default, // 文件缺省图标
      btnConfig: {
        download: this.$i18n.t("chatter.down"),
        publicLink: this.$i18n.t("label.file.publiclink"),
        viewinfo: this.$i18n.t("label.more.info"),
        share: this.$i18n.t("label.file.share"),
        delete: this.$i18n.t("label.delete"),
        editinfo: this.$i18n.t("label.modify"),
        replace: this.$i18n.t("label.replaces"),
      }, // 所有操作按钮集合
    };
  },
  methods: {
    /**
     * 取消文件预览
     */
    closePreview() {
      this.$emit("closePreview");
    },
    fileDeal(item) {
      this.$emit("fileDeal", item);
    },
    handleActions(active) {
      this.$emit("handleActions", active);
    },
  },
};
</script>

<style  lang='scss' scoped>
.preview_header {
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  padding: 0 20px;
  padding-left: 68px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  position: relative;
  .preview-header-l {
    display: flex;
    align-items: center;
    color: #fff;
    // width: 25%;
    > img {
      width: 34px;
      height: 34px;
      margin-right: 10px;
    }
    .preview-header-l-name {
      margin-right: 10px;
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: 20px;
    }
    span {
      font-size: 14px;
    }
  }
  .preview-header-m {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #dddbda;
    // width: 20%;/
    // justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    .zoom_btn {
      font-size: 23px;
      margin-right: 15px;
    }

    .preview-header-option {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin: 0 15px;
      cursor: pointer;
      > img {
        width: 17px;
        height: 17px;
        margin-right: 10px;
      }
    }
    .preview-header-option:hover {
      color: dodgerblue;
    }
  }
  .preview-header-r {
    cursor: pointer;
    // width: 25%;
    text-align: right;
  }
}

.more-button-ul {
  margin: 0;
  .more-button-li {
    height: 30px;
    cursor: pointer;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
  }
  .more-button-li:hover {
    color: dodgerblue;
  }
  .more-button-li:last-child {
    margin-bottom: 0;
  }
}
</style>